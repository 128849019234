<template>
  <div class="activevideo">
    <div class="activevideo-container">
      <div class="redtitle">
    <span>活动视频</span>
    </div>
  <div class="projectintroduct">
      <ul class="workinfo-list">
          <li v-for="(item,index) in videolist" :key="index" @click="playVideo(item.id)">
             <dt>
                 <!-- <video style="object-fit: fill; width: 100%; height: 100%" controls="controls">
                    <source :src="item.video" type="video/mp4">
                 </video> -->
				 <img :src="item.smallImage+'!353x200'" style="width: 100%; height: 100%">
             </dt>
             <dd>
                <p>
                 <span>录入时间：{{item.publishDate}}</span>
                 <span>浏览次数：{{item.views}}次</span>
                </p> 
                <h2 class="videotitle">{{item.title}}</h2>
             </dd>
			 <div class="biaoqian">
				 <img src="../../../assets/images/组 15.png" alt="">
			 </div>
			 <div class="bofang">
			 	<img src="../../../assets/images/播放.png" alt="">
			 </div>
          </li>
      </ul>
      <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
         </el-pagination>
  </div>
    </div>
  </div>
</template>

<script>
import apiUrls from '@api'
export default {
  name: 'Activevideo',
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      videolist: []
    }
  },
  created () {
    console.log(this.$route.query.type)
    this.getvideolist(this.$route.query.type)
    this.handleCurrentChange(this.$route.query.type)
  },
  methods: {
	  playVideo(id) {
	    this.$router.push({ path: "/videoShow", query: { id } });
	  },
    handleSizeChange(val) {
   
    },
    handleCurrentChange(type,val) {
 
      apiUrls.selfDynamicList({node: type, page: val===1?0:val}).then(res=>{
      this.total = res.results.pager.totalElements;
      this.videolist= res.results.data
      })
    },
    getvideolist (type) {
      apiUrls.getvolunteer({node: type}).then(res=>{
		  console.log(res.results.data)
      this.videolist= res.results.data
      }) 
    }
    }
}
</script>

<style lang="less" scoped>
.activevideo {
    background-color: #f5f5f5;
}
.activevideo-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;
    &::before {
    display: block;
    content: ".";
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    }
}
.redtitle  {
    margin-top: 10px;
    font-size: 23px;
    color: #333333;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    span {
        &:nth-child(1) {
        display: flex;
        &::before {
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        background: url('../../../assets/img/title.png') no-repeat center center;
       }
     }
       &:nth-child(2) {
           color: #ABABAB;
           font-size: 17px;
       }
    }
   
}
.projectintroduct {
    min-height: 541px;
    background-color: #ffffff;
    overflow: hidden;
    padding: 26px 23px 41px 18px;
    box-shadow: 0px 0px 18px 0px rgba(14,14,14, 0.13);
    .workinfo-list {
		&:after{
		  content:'';
		  /*这个宽度和子元素宽度一致*/
		  width: 353px;
		}
        cursor: pointer;
        display: flex;
        // justify-content: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
			position: relative;
			padding: 10px;
			// box-sizing: border-box;
			&:hover {
			  transform: translateY(-5px);
			  box-shadow: 5px 5px 5px #ccc;
			}
            width: 353px;
            min-height: 200px;
            justify-content: space-between;
            margin-bottom: 20px;
            /* &:last-child{
                margin-bottom: 0;
            } */
			.bofang {
			  position: absolute;
			  left: 50%;
			  top: 110px;
			  transform: translate(-50%, -50%);
			  z-index: 1000;
			}
			.biaoqian {
				position: absolute;
				top: 20px;
				left: 0;
			}
            dt {
                background-color: #666666;
				width: 353px;
				height: 200px;
            }
            dd {
                p{
                color: #333333;
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                margin-top: 6px;
                }
                h2 {
                   text-align: center;
                   color: #DB4041; 
                   font-weight: bolder;
                   margin-top: 16px;
                   font-size: 20px;
                }
            }
            
            span {
                color: #ABABAB;
            }
        }
    }
}
</style>
